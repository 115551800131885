export interface EventInterface {
  event: string;
}

export enum ActivityTypesEnum {
  pageView = 2,
  pageClose = 1000,
}

export interface UserDataRequest {
  prospectId: string;
  activity: ActivityDefinition;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phone?: string;
  timezone?: string;
  source?: string;
  sourceMedium?: string;
  sourceSet?: string;
  notes?: string;
  sourceCampaign?: string;
  sourceContent?: string;
  webReferrer?: string;
  webRefkeyword?: string;
  utmDevice?: string;
  utmNetwork?: string;
  utmKeyword?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  currency?: string;
  preferredLanguages: string[];
  age?: number;
  ipAddress?: string;
  ipAddresVersion?: string;
  latitude?: string;
  longitude?: string;
  googleClickId?: string;
  facebookClickId?: string;
  region?: string;
  regionCode?: string;
  countryName?: string;
  countryCode?: string;
  coutryCodeISO3?: string;
  countryInEU?: boolean;
  postal?: string;
  utcOffset?: string;
  countryCallingCode?: string;
  asn?: string;
  organization?: string;
  network?: string;
  mxProperties?: Record<string, any>;
}

export interface ActivityDefinition {
  activityCode: number;
  note?: string;
  pageTitle?: string;
  pageUrl?: string;
  pageReferrer?: string;
  mxCustomProperties?: Record<string, any>;
  activeDuration?: number;
}

export interface IpApiInterface {
  ip: string;
  version: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_name: string;
  country_code: string;
  country_code_iso3: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  country_area: number;
  country_population: number;
  asn: string;
  org: string;
  network: string;
}

export interface IpApiError {
  error: boolean;
  reason: string;
  message: string;
}

export type ApiParams = {
  cookieName: string;
  apiName: keyof UserDataRequest;
};

export interface captureEventInterface {
  activityCode: number;
  activityNote?: string;
  mxProperties?: Record<string, any>;
}

export interface PageEventInterface {
  activityCode: ActivityTypesEnum | number;
  activityNote?: string;
  activeDuration?: number;
  mxParams?: Record<string, any>;
  extraUserParams: Partial<UserDataRequest>;
}
