import {
  ActivityTypesEnum,
  captureEventInterface,
  IpApiError,
  IpApiInterface,
  PageEventInterface,
  UserDataRequest,
} from "./types";
import {
  getUTMRecordObjects,
  getCookieExpireDate,
  setIpCookies,
  getRecordObjects,
  getProspectId,
  captureEvent,
  getCookieDomain,
} from "./utils";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import {
  cookieIPCookieName,
  currentIPCookieName,
  ipCookieNames,
  leadParams,
  organizationalIdCookieName,
  prospectIdCookieName,
} from "./constants";
import pickBy from "lodash/pickBy";
import omitBy from "lodash/omitBy";

let startTime: number | null = null,
  activeTime: number = 0,
  onPageViewFn: ((data: UserDataRequest | null) => void) | undefined,
  onPageCloseFn: ((data: UserDataRequest | null) => void) | undefined;

const fetchIpInfo = async (): Promise<void> => {
  const res: IpApiInterface | IpApiError = await fetch(
    "https://ipapi.co/json/?key=BsMZIA7JXuntHkvJrGeTY2Jh6KehsMfwEZIDmjPLTZWQtL1xAV"
  ).then((res) => res.json());

  if ((res as any).error) {
    return;
  }

  setIpCookies(res as IpApiInterface);
};

const getPageEvent = async (
  pageData: PageEventInterface
): Promise<UserDataRequest | null> => {
  const prospectId = getProspectId();

  if (!prospectId) {
    console.error("Prospect Id not found while sending event");
    return null;
  }

  const utmData = getUTMRecordObjects();
  const ipInfo = await getIpInfo();

  const leadData = getRecordObjects(leadParams);

  const pageTitle = document.title;
  const pageReferrer = document.referrer;
  const pageUrl = document.URL;

  const customKeyFilter = (value: any, key: string) =>
    key.toLowerCase().startsWith("mx_custom");
  const mxCustom = pickBy(pageData.mxParams, customKeyFilter);
  const others = omitBy(pageData.mxParams, customKeyFilter);

  const requestObj: UserDataRequest = {
    ...leadData,
    ...utmData,
    ...ipInfo,
    ...pageData.extraUserParams,
    mxProperties: others,
    prospectId,
    preferredLanguages: [],
    activity: {
      activityCode: Number(pageData.activityCode),
      note: pageData.activityNote,
      pageTitle,
      pageUrl,
      pageReferrer,
      mxCustomProperties: mxCustom,
    },
  };

  return requestObj;
};

const sendEvent = async (eventData: PageEventInterface): Promise<any> => {
  const event = await getPageEvent(eventData);
  // NOTE: api call is made here for backend
  if (event) {
    if (
      event.activity.activityCode === ActivityTypesEnum.pageView &&
      onPageViewFn !== undefined
    ) {
      onPageViewFn(event);
      return;
    }
    if (
      event.activity.activityCode === ActivityTypesEnum.pageClose &&
      onPageCloseFn !== undefined
    ) {
      onPageCloseFn(event);
      return;
    }
    return await captureEvent(event);
  }
};

// * We are using sendCloseEvent in page close instead of sendEvent
// * because sendEvent uses getIpinfo and awaits it, which causes issue in sending close event
const sendCloseEvent = async (eventData: PageEventInterface): Promise<any> => {
  const pageData = eventData;

  const prospectId = getProspectId();

  if (!prospectId) {
    console.error("Prospect Id not found while sending event");
    return null;
  }

  const utmData = getUTMRecordObjects();

  const leadData = getRecordObjects(leadParams);

  const pageTitle = document.title;
  const pageReferrer = document.referrer;
  const pageUrl = document.URL;

  const customKeyFilter = (value: any, key: string) =>
    key.toLowerCase().startsWith("mx_custom");
  const mxCustom = pickBy(pageData.mxParams, customKeyFilter);
  const others = omitBy(pageData.mxParams, customKeyFilter);

  //* NOTE: if any changes occur in this object Please update in grow website also.[ lead analytics data object ]
  const activity: UserDataRequest = {
    ...leadData,
    ...utmData,
    ...pageData.extraUserParams,
    mxProperties: others,
    prospectId,
    preferredLanguages: [],
    activity: {
      activityCode: Number(pageData.activityCode),
      note: pageData.activityNote,
      pageTitle,
      pageUrl,
      pageReferrer,
      mxCustomProperties: {
        ...mxCustom,
        mx_custom_38: document.title,
        mx_custom_39: document.URL,
        mx_custom_40: pageData.activeDuration
          ? `${Math.round(pageData.activeDuration / 1000)} s`
          : undefined,
      },
    },
  };

  if (activity) {
    if (
      activity.activity.activityCode === ActivityTypesEnum.pageView &&
      onPageViewFn !== undefined
    ) {
      onPageViewFn(activity);
      return;
    }
    if (
      activity.activity.activityCode === ActivityTypesEnum.pageClose &&
      onPageCloseFn !== undefined
    ) {
      onPageCloseFn(activity);
      return;
    }
    return await captureEvent(activity);
  }
};

const crmInitLog = async (
  orgId: string,
  onPageView?: (data: UserDataRequest | null) => void,
  onPageClose?: (data: UserDataRequest | null) => void
): Promise<void> => {
  Cookies.set(organizationalIdCookieName, orgId, {
    expires: getCookieExpireDate(),
    domain: getCookieDomain(),
  });
  const lsqProspectCookie = Cookies.get(`ORG${orgId}`);
  const userDataCookie = Cookies.get(prospectIdCookieName);
  if (!lsqProspectCookie && !userDataCookie) {
    const userData = { userId: uuidv4() };
    Cookies.set(prospectIdCookieName, userData.userId, {
      expires: getCookieExpireDate(),
      domain: getCookieDomain(),
    });
  }
  if (lsqProspectCookie) {
    Cookies.set(prospectIdCookieName, lsqProspectCookie, {
      expires: getCookieExpireDate(),
      domain: getCookieDomain(),
    });
  }
  onPageCloseFn = onPageClose;
  onPageViewFn = onPageView;

  //* NOTE: if any changes occur in below prop object Please update in grow website also.
  sendEvent({
    activityCode: ActivityTypesEnum.pageView,
    extraUserParams: {},
  });
};

const crmCaptureLead = (data: captureEventInterface) => {
  // NOTE: uncomment below lines to get data from website which are using this scripts
  // sendEvent({
  //   activityCode: data.activityCode,
  //   activityNote: data.activityNote,
  //   extraUserParams: {},
  //   mxParams: data.mxProperties,
  // });
};

const onPageChange = async (): Promise<void> => {
  if (startTime) {
    const endTime = performance.now();
    activeTime += endTime - startTime;
  }
  // NOTE: Here api call is made for page close event
  sendCloseEvent({
    activityCode: ActivityTypesEnum.pageClose,
    activeDuration: activeTime,
    extraUserParams: {},
  });
};

const getIpInfo = async (): Promise<Record<string, any>> => {
  const currentIp = Cookies.get(currentIPCookieName);
  const cookieIp = Cookies.get(cookieIPCookieName);
  if (currentIp != cookieIp) {
    await fetchIpInfo();
  }
  return getRecordObjects(ipCookieNames);
};

window.addEventListener("beforeunload", async () => {
  onPageChange();
});

window.addEventListener("load", () => {
  startTime = performance.now();
});

window.addEventListener("focus", () => {
  startTime = performance.now();
});

window.addEventListener("blur", () => {
  if (!startTime) {
    return;
  }
  const endTime = performance.now();
  activeTime += endTime - startTime;
  startTime = null;
});

(window as any).crmInitLog = crmInitLog;
(window as any).crmCaptureLead = crmCaptureLead;
